<template>

 	<div id="Receitas" v-if="dataReady">

 		<div class="container-fluid text-center">
			<div class="container receitas-page-header">
				<img src="@/images/textos/saborosas-receitas.svg" style="max-width:100%;" alt="Saborosas Receitas">
			</div>
		</div>

		<div class="container mb20">	
			<div class="row receitas" v-for="receita in receitas" :key="receita.titulo">

				<router-link :to="'/receita/' + receita.titulo_slug" class="col-12 col-md-7 col-lg-6 offset-lg-1 receitas-thumb">
					<div class="position-relative moldura3">
						<img :src="pageBaseUrl+'/'+receita.image.path" :alt="receita.titulo" style="width:100%;">
						<div class="overlay d-flex flex-column align-items-center justify-content-center">
							VER RECEITA
							<div v-if="receita.videoid" class="video-icon mt10">
								<img src="@/images/icon-video.svg"> INCLUI VÍDEO
							</div>
						</div>
					</div>
				</router-link>

				<div class="receitas-title col-12 col-md-5 col-lg-4 d-flex align-items-center">
					<div>
						<p v-if="receita.chef" class="chef">Do chef  {{ receita.chef }}</p>
						<h3>{{ receita.titulo }}</h3>
						<p v-if="receita.doses" class="num-pessoas">PARA {{ receita.doses }} PESSOAS</p>
						<div v-if="receita.videoid" class="video-icon mt20">
							<img src="@/images/icon-video.svg"> INCLUI VÍDEO
						</div>
					</div>
				</div>

			</div>
		</div>

		<MainFooter />

 	</div>

</template>

<script>
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import axios from "axios";

export default {
	name: "Receitas",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			pageBaseUrl: globals.BASE_URL,
			receitas: null
		};
	},
	beforeCreate: function() {
        document.body.className = 'pag-receitas';
    },
	mounted () {
		axios
			.get(globals.COCKPIT_SERVER+"collections/get/receitas"+globals.COCKPIT_TOKEN+"&sort[_created]=-1")
			.then(response => {
				this.receitas = response.data.entries
				this.dataReady = true
				this.changeMetaData()
			})
		;
	},
	metaInfo() {
		return {
			title: 'Receitas'
		}
	},
	methods: {
		changeMetaData() {
			this.$parent.metaTitle = this.$metaInfo.title;
			this.$parent.metaDescription = 'Conheça as receitas mais saborosas com batatas fritas Cerro da Águia!';
		}
	}
};
</script>

